var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "js-common-wrap" }, [
    _c("div", { staticClass: "js-common-head" }, [
      _c("label", [_vm._v("角色权限设置")]),
      _c(
        "div",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.save.apply(null, arguments)
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "js-common-content js-content-roleDetails" },
      [
        _c(
          "el-select",
          {
            staticClass: "role-box",
            attrs: { placeholder: "请选择角色", size: "small" },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.currentRole,
              callback: function ($$v) {
                _vm.currentRole = $$v
              },
              expression: "currentRole",
            },
          },
          _vm._l(_vm.roles, function (item) {
            return _c("el-option", {
              key: item.id,
              attrs: { label: item.name, value: item.id },
            })
          }),
          1
        ),
        _c(
          "el-checkbox-group",
          {
            staticClass: "jurs-box",
            model: {
              value: _vm.jurs,
              callback: function ($$v) {
                _vm.jurs = $$v
              },
              expression: "jurs",
            },
          },
          _vm._l(_vm.jurisdiction, function (jur) {
            return _c(
              "el-checkbox",
              { key: jur.id, attrs: { label: jur.id } },
              [_vm._v(_vm._s(jur.name))]
            )
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }