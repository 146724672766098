<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>角色权限设置</label>
      <div>
        <el-button type="primary" size="small" @click.stop="save">保存</el-button>
      </div>
    </div>
    <div class="js-common-content js-content-roleDetails">
      <el-select class="role-box" v-model="currentRole" placeholder="请选择角色" size="small" @change="handleChange">
        <el-option
          v-for="item in roles"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-checkbox-group class="jurs-box" v-model="jurs">
        <el-checkbox v-for="jur in jurisdiction" :key="jur.id" :label="jur.id">{{jur.name}}</el-checkbox>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      currentRole: '',
      roles: '',
      jurisdiction: '',
      jurs: []
    }
  },
  mounted() {
    this.getRole()
    this.getRight()
  },
  methods: {
    getRole(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/role/'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.roles = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    getRight(){
      this.$axios({
        method: 'get',
        url: 'api/csp/mgr/v1/permission/'
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.jurisdiction = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleChange(){
      this.$axios({
        method: 'get',
        url: `api/csp/mgr/v1/role/permissionIds/${this.currentRole}`,
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.jurs = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    save(){
      if(!this.currentRole){
        this.$message({
          type: 'error',
          message: '请选择角色！',
          duration: 1000
        })
        return
      }
      this.$axios({
        method: 'post',
        url: `api/csp/mgr/v1/role/bindPermission`,
        data:{
          data:{
            roleId: this.currentRole,
            permissionIds : this.jurs
          }
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.$message({
            type: 'success',
            message: '权限保存成功！',
            duration: 1000
          })
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.js-content-roleDetails{
  margin-top: 20px;
  padding:20px;
  background: #fff;
  display: flex;
  .role-box{
    margin-right: 20px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .jurs-box{
    label{
      width: 150px;
      line-height: 32px;
    }
  }
}
</style>
